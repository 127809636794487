<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Battery Details</p>
    <div class="flex justify-content-start flex-wrap mb-8">
        <div style="width: 225px;">
            <p class="business-type-label">Battery Maker</p>
            <p class="business-type-value">{{ user?.batteryMaker?.name }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Battery Model</p>
            <p class="business-type-value">{{ user?.batteryModel }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Manufactured Date</p>
            <p class="business-type-value">{{ user?.batteryMfg | date:'MMM d, yyyy' }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Purchased Date</p>
            <p class="business-type-value">{{ user?.batteryPurchasedOn | date:'MMM d, yyyy' }}</p>
        </div>
    </div>
</div>