<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Snapshots</p>
    <div class="flex justify-content-start flex-wrap">
        <div class="">
            <div style="width: 225px;">
                <p class="business-type-label">Vehicle Registration Renewal</p>
                <p class="business-type-value">{{ user.vin }}</p>
            </div>
            <div class="business-info">
                <p class="business-type-label">Periodic Service Reminders</p>
                <p class="business-type-value">{{ user.vehicleMaker?.name }}</p>
            </div>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <div>
                <p class="business-type-label">Pollution Control Renewal</p>
                <p class="business-type-value">{{ user.vehicleRegistrationNumber }}</p>
            </div>
            <div class="business-info">
                <p class="business-type-label">Battery Expiry (approx)</p>
                <p class="business-type-value">[Date]</p>
            </div>
        </div>

        <div style="width: 255px; padding-left: 52px;">
            <div>
                <p class="business-type-label">Pollution Control Renewal</p>
                <p class="business-type-value">{{ user.vehicleRegistrationNumber }}</p>
            </div>
            <div class="business-info">
                <p class="business-type-label">Tyre Expiry (approx)</p>
                <p class="business-type-value">[Date]</p>
            </div>
        </div>
    </div>
</div>