import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-additional-vehicle-details',
  templateUrl: './additional-vehicle-details.component.html',
  styleUrl: '../user-details.component.scss'
})
export class AdditionalVehicleDetailsComponent {
  @Input() user!: any
}
