<div class="subscription-container">
    <div class="subscription-header">
      <div style="margin-bottom: 43px;" class="font-semibold text-xl mt-5">Pricing</div>
    </div>
    <div>
      <div class="plan-card" [class.selected]="selectedPlan === 'Basic Plan'" (click)="selectPlan('Basic Plan')">
        <p class="plan-title">Standard Plan</p>
        <p class="plan-price">₹2000.00 <span style="font-size: 16px;font-weight: 600;">/per car/mo</span></p>
        <p>(Billed annually)</p>
        <div class="plan-features">
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check" style="font-size: 12px;"></i>
            </div>
            <span class="feature-text">Access to all basic features</span>
          </div>
          <div class="flex justify-content-center flex-wrap" style="cursor: pointer;">
            <button type="submit" class="btn btn-primary" [class.selected]="selectedPlan === 'Basic Plan'" (click)="onSubmit()">
              Select Plan
              <span *ngIf="selectedPlan === 'Basic Plan'"> ➔</span>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="plan-card" [class.selected]="selectedPlan === 'Premium Plan'" (click)="selectPlan('Premium Plan')">
        <p class="plan-title">Premium Plan</p>
        <p class="plan-price">₹600.00 <span style="font-size: 16px;font-weight: 600;">/per car/mo</span></p>
        <p>(Billed annually)</p>
        <div class="plan-features">
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check" style="font-size: 12px;"></i>
            </div>
            <span class="feature-text">Access to all basic features</span>
          </div>
          <div class="flex justify-content-center flex-wrap">
            <button type="submit" class="btn btn-primary" [class.selected]="selectedPlan === 'Premium Plan'" (click)="onSubmit()">
              Select Plan
              <span *ngIf="selectedPlan === 'Premium Plan'"> ➔</span>
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  