<div class="form-container">
  <!-- Loader at the top -->
  <div class="loading-overlay" *ngIf="isLoading">
    <div class="loading-indicator"></div>
  </div>

  <!-- Form Content -->
  <p-toast></p-toast>
  <div *ngIf="!isLoading">
    <div style="margin-bottom: 43px;" class="font-semibold text-xl mt-5">
      Vehicle details
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
      </formly-form>
      <button class="custom-upload-button" type="submit">
        Next
        <span class="arrow">➔</span>
      </button>
    </form>

    <!-- Display the uploaded file image using p-image -->
    <div class="uploaded-image-container" *ngIf="fileUrl">
      <!-- <p-image src="{{ fileUrl }}" alt="Selected file" class="image-box" [preview]="true"></p-image> -->
    </div>
  </div>
</div>