<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Tyre Details</p>

    <p class="tyre-position">{{user?.tyreDetails[0]?.position}}</p>
    <div class="flex justify-content-start flex-wrap">
        <div style="width: 225px;">
            <p class="business-type-label">Specification</p>
            <p class="business-type-value">{{ user?.tyreDetails[0]?.specification }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Date Codes</p>
            <p class="business-type-value">{{ user?.tyreDetails[0]?.dateCode }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Brand Name</p>
            <p class="business-type-value">{{ user?.tyreDetails[0]?.brandName }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Type</p>
            <p class="business-type-value">{{ user?.tyreDetails[0]?.tyreType?.name }}</p>
        </div>
    </div>

    <!-- position-2 -->
    <p style="margin-top: 43px;" class="tyre-position">{{user?.tyreDetails[1]?.position}}</p>
    <div class="flex justify-content-start flex-wrap">
        <div style="width: 225px;">
            <p class="business-type-label">Specification</p>
            <p class="business-type-value">{{ user?.tyreDetails[1]?.specification }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Date Codes</p>
            <p class="business-type-value">{{ user?.tyreDetails[1]?.dateCode }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Brand Name</p>
            <p class="business-type-value">{{ user?.tyreDetails[1]?.brandName }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Type</p>
            <p class="business-type-value">{{ user?.tyreDetails[1]?.tyreType?.name }}</p>
        </div>
    </div>

    <!-- position-3 -->
    <p style="margin-top: 43px;" class="tyre-position">{{user?.tyreDetails[2]?.position}}</p>
    <div class="flex justify-content-start flex-wrap">
        <div style="width: 225px;">
            <p class="business-type-label">Specification</p>
            <p class="business-type-value">{{ user?.tyreDetails[2]?.specification }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Date Codes</p>
            <p class="business-type-value">{{ user?.tyreDetails[2]?.dateCode }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Brand Name</p>
            <p class="business-type-value">{{ user?.tyreDetails[2]?.brandName }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Type</p>
            <p class="business-type-value">{{ user?.tyreDetails[2]?.tyreType?.name }}</p>
        </div>
    </div>

    <!-- position-4 -->
    <p style="margin-top: 43px;" class="tyre-position">{{user?.tyreDetails[3]?.position}}</p>
    <div class="flex justify-content-start flex-wrap">
        <div style="width: 225px;">
            <p class="business-type-label">Specification</p>
            <p class="business-type-value">{{ user?.tyreDetails[3]?.specification }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Date Codes</p>
            <p class="business-type-value">{{ user?.tyreDetails[3]?.dateCode }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Brand Name</p>
            <p class="business-type-value">{{ user?.tyreDetails[3]?.brandName }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Type</p>
            <p class="business-type-value">{{ user?.tyreDetails[3]?.tyreType?.name }}</p>
        </div>
    </div>

    <!-- spare parts -->
    <p style="margin-top: 43px;" class="tyre-position">{{user?.tyreDetails[4]?.position}}</p>
    <div class="flex justify-content-start flex-wrap mb-8">
        <div style="width: 225px;">
            <p class="business-type-label">Specification</p>
            <p class="business-type-value">{{ user?.tyreDetails[4]?.specification }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Date Codes</p>
            <p class="business-type-value">{{ user?.tyreDetails[4]?.dateCode }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Brand Name</p>
            <p class="business-type-value">{{ user?.tyreDetails[4]?.brandName }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Type</p>
            <p class="business-type-value">{{ user?.tyreDetails[4]?.tyreType?.name }}</p>
        </div>
    </div>
</div>