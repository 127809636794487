import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { UserService } from '../../../../Service/User/user.service';
import { UserFormStateService } from '../../Service/formStateService';

@Component({
  selector: 'app-user-personal-detail',
  templateUrl: './user-personal-detail.component.html',
  styleUrls: ['./user-personal-detail.component.scss'],
})
export class UserPersonalDetailComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];

  countryId: any;
  countryName!: string;
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  receivedObject: any;
  customerId: any;

  constructor(
    private api: ServiceProviderService,
    private formStateService: UserFormStateService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadFormData();

    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      const storedCountryName = tokenDetails.country?.name;
      if (this.countryId) {
        console.log(this.countryId);
        this.model.countryId = this.countryId;
        this.form.patchValue({ countryId: this.countryId });
        this.initializeField();
      }
    }
    this.initializeField();

  }

  ngOnDestroy() {
    this.saveFormData();
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }
  saveFormData() {
    const formData = { ...this.form.value };
    this.formStateService.setFormData('personal-details', formData);
    console.log(formData);
  }

  loadFormData() {
    const savedData = this.formStateService.getFormData('personal-details');
    if (savedData) {
      console.log('Saved Data:', savedData);
      this.model = savedData;
      this.form.patchValue(savedData);
    }
  }

  initializeField() {
    this.fields = [
      {
        template: '<div class="text-base font-semibold mb-4">Basics</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-4',
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name of the Customer',
              placeholder: 'Enter name',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.minLength(3)],
            },
            validation: {
              messages: {
                required: 'Name is required',
                minlength: 'Name should be at least 3 characters long',
              },
            },
          },
          {
            className: 'field col-3',
            key: 'phone',
            type: 'input',
            templateOptions: {
              label: 'Phone number',
              placeholder: '+91 1234567890',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.pattern(/^\d{10}$/)],
            },
            validation: {
              messages: {
                required: 'Phone number is required',
                pattern: 'Phone number must be exactly 10 digits',
              },
            },
          },
          {
            className: 'field col-3',
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email',
              placeholder: 'Enter email address',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.email],
            },
            validation: {
              messages: {
                required: 'Email is required',
                email: 'Invalid email format',
              },
            },
          },
        ],
      },
      {
        template:
          '<div class="text-base font-semibold mb-4 mt-5">Address</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-4',
            key: 'countryId',
            type: 'select',
            templateOptions: {
              disabled: true,
              options: this.api.getCountry(''),
              placeholder: 'Select a country',
              valueProp: 'countryId',
              labelProp: 'name',
              label: 'Country',
              hideRequiredMarker: true,
            },
            defaultValue: this.countryName,
            hooks: {
              onInit: (field) => {
                if (this.countryName) {
                  field.formControl?.setValue(this.model.countryId);
                }
              },
            },
          },
          {
            className: 'field col-3',
            key: 'stateId',
            type: 'select',
            templateOptions: {
              placeholder: 'Select State',
              options: this.api.getStates(this.countryId),
              valueProp: 'stateId',
              labelProp: 'name',
              label: 'State',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'city',
            type: 'input',
            templateOptions: {
              label: 'City',
              placeholder: 'Select city',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5 mt-3',
            key: 'address',
            type: 'input',
            templateOptions: {
              label: 'Address',
              placeholder: 'Enter address',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5 mt-3',
            key: 'zip',
            type: 'input',
            templateOptions: {
              label: 'Pincode',
              placeholder: 'Enter pincode',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.pattern(/^\d{6}$/)],
            },
            validation: {
              messages: {
                required: 'Pincode is required',
                pattern: 'Pincode must be exactly 6 digits',
              },
            },
          },
        ],
      },
    ];
  }
  populateFormWithServiceSeekersDetails(res: any) {
    this.customerId = res.customerId;
    console.log(this.customerId)
    this.model.name = res?.name || '';
    this.model.phone = res?.phoneNumber || '';
    this.model.email = res?.emailAddress || '';
    this.model.address = res?.address || '';
    this.model.stateId = res?.state?.stateId || '';
    this.model.countryId = this.countryId;
    this.model.city = res?.city || '';
    this.model.zip = res?.zip || '';

    this.form.patchValue(this.model);
    this.formStateService.setFormData('personal-details', this.model);
    this.cdr.detectChanges();
  }
  onSubmit() {
    if (this.form.valid) {
      const transformedData = this.transformFormData(this.form.value);
      this.formStateService.setPersonalDetails(transformedData);
      console.log('Transformed data',transformedData);
      this.nextSection.emit();
    }
  }

  transformFormData(formData: any): any {
    const transformedData: any = {
      name: formData.name,
      phoneNumber: formData.phone,
      emailAddress: formData.email,
      address: formData.address,
      stateId: formData.stateId,
      countryId: this.countryId,
      city: formData.city,
      zip: formData.zip,
    }
    if (this.customerId) {
      transformedData.customerId = this.customerId;
    }
    return transformedData;
  }
}
