<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Insurance details</p>
    <div class="flex justify-content-start flex-wrap mb-8">
        <div style="width: 225px;">
            <p class="business-type-label">Insurance company</p>
            <p class="business-type-value">{{ user?.insuranceCompany?.name }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Insurance Policy Number</p>
            <p class="business-type-value">{{ user?.insurancePolicyNumber }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Insurance Type</p>
            <p class="business-type-value">{{ user?.insuranceType?.name }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Insurance Valid Till</p>
            <p class="business-type-value">{{ user?.insuranceValidTillDate| date:'MMM d, yyyy' }}</p>
        </div>
    </div>
</div>