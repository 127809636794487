import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UserService } from '../../../../Service/User/user.service';
import { UserFormStateService } from '../../Service/formStateService';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { MessageService } from 'primeng/api';
import { ToastService } from '../../../../Service/ToastService/toast.service';
import { ServiceAdvisorFormStateService } from '../../../service-advisors/service/service-advisor-formStateService';

@Component({
  selector: 'app-add-vehicle-details',
  templateUrl: './add-vehicle-details.component.html',
  styleUrls: ['./add-vehicle-details.component.scss'],
})
export class AddVehicleDetailsComponent implements OnInit {
  countryId: any;
  isLoading = false;
  form = new FormGroup({});
  isFieldsDisabled = true;
  model: any = {
    vehicleRegistrationNumber: '',
    ownerName: '',
    nationality: '',
    countryId: '',
    state: '',
    city: '',
    vehicleOwnerAddress: '',
    pincode: '',
    vin: '',
    vehicleMakerId: '',
    vehicleModelId: '',
    modelyear: '',
    vehicleOriginId: '',
    color: '',
    paintingType: '',
    engineCapacity: '',
    emptyWeight: '',
    maximumLoad: '',
    yearOfManufacture: '',
    numberofPassangers: '',
    numberofAxes: '',
    basicBaseNumberChasis: '',
    serialNumber: '',
    licenceValidityStartDate: '',
    licenceValidityEndDate: '',
    dateOfFirstRegistration: '',
    licensingAuthority: '',
    mortgage: '',
    additionalNotes: '',
  };
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};

  @Output() nextSection = new EventEmitter<void>();
  countryName!: string;
  fileUrl: string | undefined;
  displayDialog: boolean = false;
  receivedObject: any;
  isFOrmPopulated: boolean = false;
  customerId: any
  editMode = false
  constructor(
    private api: UserService,
    private formStateService: UserFormStateService,
    private advisorService: ServiceAdvisorFormStateService,
    private serviceApi: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.loadFormData()
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      this.countryName = tokenDetails.country?.name;
      if (this.countryId) {
        this.model.countryId = this.countryId;
        this.form.patchValue({ countryId: this.countryId });
        this.initializeField();
      }
      this.customerId = this.formStateService.getUserId();
      this.editMode = !!this.customerId; // If customerId exists, set editMode to true

      // Initialize the form fields after determining editMode
      this.initializeField();

    }

    // const selectedFile = this.advisorService.getFormData('upload-documents');
    // if (selectedFile) {
    //   this.fileUrl = selectedFile;
    // }
  }

  ngOnDestroy() {
    this.saveFormData();
  }
  initializeField() {
    this.fields = [
      ...this.vehicleOwnerDetails(),
      ...this.vehicleDetails(),
      ...this.othervehicleDetails(),
    ];

    const countryField = this.findFieldByKey('countryId');
    if (countryField) {
      countryField.hooks = {
        onInit: (field) => {
          field.formControl?.valueChanges.subscribe(
            (selectedCountryId: string) => {
              this.serviceApi.getCountry('').subscribe((countries) => {
                const selectedCountry = countries.find(
                  (country: any) => country.countryId === selectedCountryId
                );
                if (selectedCountry && selectedCountry.name === 'India') {
                  this.form.patchValue({ nationality: 'Indian' });
                } else {
                  this.form.patchValue({ nationality: '' }); // Reset or set to other nationalities based on selected country
                }
              });
            }
          );
        },
      };
    }
  }

  vehicleOwnerDetails(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5 mt-1',
            key: 'vehicleRegistrationNumber',
            type: 'input',
            templateOptions: {
              label: 'Vehicle Registration Number',
              placeholder: 'Enter vehicle registration number',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
            validation: {
              messages: {
                required: 'Vehicle Registration Number is required',
              },
            },
          },
          {
            className: 'field col-3 mt-5',
            type: 'button',
            templateOptions: {
              label: 'Access RC Details',
              text: 'Check',
              onClick: () => this.checkVehicleRegistrationNumber(),
              disabled: this.editMode
            },
          },
        ],
      },
      {
        template:
          '<div class="text-base font-semibold mb-4 mt-5">Vehicle Owner Details</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'ownerName',
            type: 'input',
            templateOptions: {
              label: 'Owner name',
              placeholder: 'Enter vehicle owner name',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'nationality',
            type: 'input',
            templateOptions: {
              label: 'Nationality',
              placeholder: 'Mention your nationality here',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-4',
            key: 'countryId',
            type: 'select',
            templateOptions: {
              options: this.serviceApi.getCountry(''),
              placeholder: 'Select a country',
              valueProp: 'countryId',
              labelProp: 'name',
              label: 'Country',
              hideRequiredMarker: true,
              disabled: this.editMode
            },
            hooks: {
              onInit: (field) => {
                if (this.countryName) {
                  field.formControl?.setValue(this.countryId);
                }
              },
            },
          },
          {
            className: 'field col-3',
            key: 'stateId',
            type: 'select',
            templateOptions: {
              placeholder: 'Select State',
              options: this.serviceApi.getStates(this.countryId),
              valueProp: 'stateId',
              labelProp: 'name',
              label: 'State',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-3',
            key: 'city',
            type: 'input',
            templateOptions: {
              label: 'City',
              placeholder: 'Enter city',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'vehicleOwnerAddress',
            type: 'input',
            templateOptions: {
              label: 'Address',
              placeholder: 'Enter vehicle owner address',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5 section-spacer',
            key: 'pincode',
            type: 'input',
            templateOptions: {
              label: 'Pin code',
              placeholder: 'Enter pin code',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
    ];
  }

  vehicleDetails(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="text-base font-semibold mb-4 mt-6">Basic Vehicle Details</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'vin',
            type: 'input',
            templateOptions: {
              label: 'VIN',
              placeholder: 'Enter vehicle identification number',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'vehicleMaker',
            type: 'select',
            templateOptions: {
              options: this.api.getVehicleMakers(''),
              valueProp: 'vehicleMakerId',
              labelProp: 'name',
              label: 'Vehicle maker',
              placeholder: 'Enter vehicle maker',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'vehicleModelId',
            type: 'select',
            templateOptions: {
              options: this.api.getVehicleModels(''),
              valueProp: 'vehicleModelId',
              labelProp: 'name',
              label: 'Vehicle model',
              placeholder: 'Enter vehicle model',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'modelyear',
            type: 'custom-yr',
            templateOptions: {
              label: 'Model year',
              placeholder: 'Enter model year (mm/yy)',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'vehicleOriginId',
            type: 'select',
            templateOptions: {
              options: this.api.getVehicleOrigin(''),
              label: 'Origin',
              placeholder: 'Enter origin',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'vehicleOriginId',
              labelProp: 'name',
              disabled: this.editMode
            },
          },
          {
            className: 'field col-3',
            key: 'color',
            type: 'input',
            templateOptions: {
              label: 'Color',
              placeholder: 'Enter vehicle color',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-2',
            key: 'paintingType',
            type: 'select',
            templateOptions: {
              options: this.api.getPaintingTypes(),
              valueProp: 'paintingTypeId',
              labelProp: 'name',
              label: 'Painting type',
              placeholder: 'Enter painting type',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
    ];
  }

  othervehicleDetails(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="text-base font-semibold mb-4 mt-6">Other Vehicle Details</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'engineCapacity',
            type: 'input',
            templateOptions: {
              label: 'Engine capacity',
              placeholder: 'Enter engine capacity',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-3',
            key: 'emptyWeight',
            type: 'input',
            templateOptions: {
              label: 'Empty weight',
              placeholder: 'Enter weight (in kgs)',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-2',
            key: 'maximumLoad',
            type: 'input',
            templateOptions: {
              label: 'Maximum load',
              placeholder: 'Enter maximum load (in kgs)',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'yearOfManufacture',
            type: 'custom-yr',
            templateOptions: {
              label: 'Year of manufacture',
              placeholder: 'Select a year',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-3',
            key: 'numberofPassangers',
            type: 'select',
            templateOptions: {
              label: 'Number of passengers',
              placeholder: 'Select capacity',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode,
              options: [
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
                { label: '9', value: 9 },
                { label: '10', value: 10 },
                { label: '15', value: 15 },
                { label: '20', value: 20 },
                { label: '30', value: 30 },
                { label: '40', value: 40 },
                { label: '50+', value: 50 },
              ],
            },
          },
          {
            className: 'field col-2',
            key: 'numberofAxes',
            type: 'select',
            templateOptions: {
              label: 'Number of axes',
              placeholder: 'Select number of axles',
              required: true,
              hideRequiredMarker: true,
              options: [
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
              ],
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'basicBaseNumberChasis',
            type: 'input',
            templateOptions: {
              label: 'Basic base number chasis',
              placeholder: 'Enter basic number chasis',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'serialNumber',
            type: 'input',
            templateOptions: {
              label: 'Serial number',
              placeholder: 'Enter serial number',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'licenceValidityStartDate',
            type: 'date-range',
            templateOptions: {
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
            validation: {
              messages: {
                required: 'This is a required field',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'licenceValidityEndDate',
            type: 'date-range-min',
            templateOptions: {
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
            validation: {
              messages: {
                required: 'This is a required field',
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'dateOfFirstRegistration',
            type: 'custom-dt',
            templateOptions: {
              label: 'Date of first registration',
              placeholder: 'Enter Date of first registration',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'licensingAuthority',
            type: 'input',
            templateOptions: {
              label: 'Licensing authority',
              placeholder: 'Enter licensing authority',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'mortgage',
            type: 'input',
            templateOptions: {
              label: 'Mortgage (if any)',
              placeholder: 'Enter the details',
              disabled: this.editMode
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col',
            key: 'additionalNotes',
            type: 'textarea',
            templateOptions: {
              disabled: this.editMode,
              label: 'Additional notes (if any)',
              placeholder: 'Enter a description...',
            },
          },
        ],
      },
      {
        template:
          '<div class="text-base font-semibold mb-4 mt-4">Current Odometer Reading</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'odoReadingDate',
            type: 'custom-dt',
            templateOptions: {
              label: 'ODO Reading Date',
              placeholder: 'Enter basic number chasis',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
          {
            className: 'field col-5',
            key: 'odoReading',
            type: 'input',
            templateOptions: {
              label: 'ODO Reading (in Kms)',
              placeholder: 'Enter Readings',
              required: true,
              hideRequiredMarker: true,
              disabled: this.editMode
            },
          },
        ],
      },
    ];
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }
  checkVehicleRegistrationNumber() {
    const registrationControl = this.form.get('vehicleRegistrationNumber');
    if (registrationControl && registrationControl.valid) {
      const registrationNumber = this.model.vehicleRegistrationNumber;
      this.isLoading = true;

      this.api.getRegistrationDetails(registrationNumber).subscribe(
        (response) => {
          if (!response.isSuccess) {
            let errorMessage = 'Invalid RC number, please provide a valid one.';
            if (response.errors && response.errors.length > 0) {
              try {
                errorMessage = response.errors[0].message;
              } catch (e) {
                console.error('Error parsing the error message:', e);
              }
            }
            this.toastService.showToast(errorMessage, 'error');
            this.isLoading = false;
            this.cdr.detectChanges();
            return;
          }

          // Reinitialize dropdowns after successful RC details fetching
          this.reinitializeDropdowns();

          console.log('API Response:', response.result);
          const vehicleDetails = response.result;
          this.api.setVehicleDetails(vehicleDetails);

          // Set the data for insurance details
          this.api.setInsuranceDetails(vehicleDetails);
          this.populateFormWithVehicleDetails(vehicleDetails);
          this.isLoading = false;
          this.toastService.showToast('Fetched RC details successfully', 'success');
          this.cdr.detectChanges();

          // If vehicle details contain a country, patch it in the form
          if (vehicleDetails.country?.countryId) {
            this.form.patchValue({
              countryId: vehicleDetails.country.countryId,
            });
          }
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching vehicle details:', error);
          this.toastService.showToast(
            'An error occurred while fetching vehicle details',
            'error'
          );
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      );
    } else {
      registrationControl?.markAsTouched();
      this.toastService.showToast(
        'Vehicle registration number is not provided or invalid.',
        'error'
      );
      console.error('Vehicle registration number is not provided.');
    }
  }

  reinitializeDropdowns() {
    console.log('Reinitializing dropdowns...');

    // Reinitialize Vehicle Makers
    this.api.getVehicleMakers('').subscribe(
      (makersResponse) => {
        try {
          const parsedResponse =
            typeof makersResponse === 'string'
              ? JSON.parse(makersResponse)
              : makersResponse;
          console.log('Vehicle Makers fetched:', parsedResponse);
          const vehicleMakerField = this.findFieldByKey('vehicleMaker');
          if (vehicleMakerField) {
            vehicleMakerField.templateOptions!.options = parsedResponse;
            console.log('Vehicle Maker dropdown updated.');
            this.cdr.detectChanges();
          } else {
            console.log('Vehicle Maker field not found.');
          }
        } catch (error) {
          console.error('Failed to parse vehicle makers response:', error);
        }
      },
      (error) => {
        console.error('Error fetching vehicle makers:', error);
      }
    );

    // Reinitialize State
    this.serviceApi.getStates('').subscribe(
      (res) => {
        try {
          const parsedResponse =
            typeof res === 'string' ? JSON.parse(res) : res;
          console.log('States fetched:', parsedResponse);
          const stateField = this.findFieldByKey('stateId');
          if (stateField) {
            stateField.templateOptions!.options = parsedResponse;
            console.log('State dropdown updated.');
            this.cdr.detectChanges();
          } else {
            console.log('State field not found.');
          }
        } catch (error) {
          console.error('Failed to parse states response:', error);
        }
      },
      (error) => { }
    );

    // Reinitialize Vehicle Models
    this.api.getVehicleModels('').subscribe(
      (modelsResponse) => {
        try {
          const parsedResponse =
            typeof modelsResponse === 'string'
              ? JSON.parse(modelsResponse)
              : modelsResponse;
          console.log('Vehicle Models fetched:', parsedResponse);
          const vehicleModelField = this.findFieldByKey('vehicleModelId');
          if (vehicleModelField) {
            vehicleModelField.templateOptions!.options = parsedResponse;
            this.cdr.detectChanges();
          } else {
            console.log('Vehicle Model field not found.');
          }
        } catch (error) {
          console.error('Failed to parse vehicle models response:', error);
        }
      },
      (error) => {
        console.error('Error fetching vehicle models:', error);
      }
    );
  }

  // Utility method to find a field by key
  findFieldByKey(key: string): FormlyFieldConfig | undefined {
    for (const fieldGroup of this.fields) {
      if (fieldGroup.fieldGroup) {
        const foundField = fieldGroup.fieldGroup.find((fg) => fg.key === key);
        if (foundField) {
          return foundField;
        }
      }
    }
    return undefined;
  }

  populateFormWithVehicleDetails(response: any) {
    const formatMonthYear = (month: number, year: number): string => {
      if (!month || !year) {
        return '';
      }
      const formattedMonth = month.toString().padStart(2, '0');
      return `${formattedMonth}/${year}`;
    };

    const formatDate = (date: string): string => {
      const d = new Date(date);
      if (isNaN(d.getTime())) return ''; // Check if date is valid
      return `${d.getFullYear()}-${(d.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
    };

    const fieldsToCheck = [
      { key: 'ownerName', value: response?.ownerName },
      { key: 'vehicleOwnerAddress', value: response?.currentAddressLine1 },
      {
        key: 'city',
        value: response?.currentDistrictName || response?.currentAddressLine3,
      },
      { key: 'stateId', value: response?.state?.stateId },
      { key: 'pincode', value: response?.currentPincode },
      { key: 'vin', value: response?.chassisNo },
      { key: 'vehicleMaker', value: response?.vehicleMaker?.vehicleMakerId },
      { key: 'vehicleModelId', value: response?.model?.vehicleModelId },
      {
        key: 'modelyear',
        value: formatMonthYear(
          response?.manufacturingMon,
          response?.manufacturingYr
        ),
      },
      { key: 'vehicleOriginId', value: response?.vehicleOriginId },
      { key: 'color', value: response?.color },
      { key: 'paintingType', value: response?.paintingType },
      { key: 'engineCapacity', value: response?.cubicCap },
      { key: 'emptyWeight', value: response?.unladenWeight },
      { key: 'maximumLoad', value: response?.vehicleGrossWeight },
      {
        key: 'yearOfManufacture',
        value: formatMonthYear(
          response?.manufacturingMon,
          response?.manufacturingYr
        ),
      },
      { key: 'numberofPassangers', value: response?.vehicleSeatCapacity },
      { key: 'numberofAxes', value: response?.numberofAxes },
      { key: 'basicBaseNumberChasis', value: response?.chassisNo },
      { key: 'serialNumber', value: response?.engineNo },
      {
        key: 'dateOfFirstRegistration',
        value: response?.regDate ? formatDate(response.regDate) : '',
      },
      { key: 'licensingAuthority', value: response?.officeName },
      { key: 'mortgage', value: response?.mortgage },
      { key: 'additionalNotes', value: response?.additionalNotes },

      {
        key: 'licenceValidityStartDate',
        value: response?.licenceValidityStartDate,
      },
      {
        key: 'licenceValidityEndDate',
        value: response?.licenceValidityEndDate,
      },
      { key: 'odoReadingDate', value: response?.odoReadingDate },
      { key: 'odoReading', value: response?.odoReading },
    ];

    fieldsToCheck.forEach((field) => {
      this.model[field.key] = field.value || '';
      if (!field.value) {
        const formControl = this.form.get(field.key);
        if (formControl) {
          formControl.markAsTouched();
          formControl.setErrors({ notPopulated: true });
        }
      }
    });

    // Patch the form with the updated model
    this.form.patchValue(this.model);
  }

  showImageDialog() {
    this.displayDialog = true;
  }

  saveFormData() {
    const formData = this.form.getRawValue();
    const transformedData = this.transformFormData(formData);
    this.formStateService.setVehicleDetails(transformedData)
    this.formStateService.setFormData('vehicle-details', formData);
    console.log(formData);
  }

  loadFormData() {
    const savedData = this.formStateService.getFormData('vehicle-details');
    console.log(savedData);
    if (savedData) {
      this.model = savedData;
      this.isFOrmPopulated = true;
      this.form.patchValue(savedData);
    }
  }

  onSubmit() {
    const transformedData = this.transformFormData(this.form.value);
    this.formStateService.setVehicleDetails(transformedData);
    console.log(transformedData);
    this.nextSection.emit();
  }

  transformFormData(formData: any): any {
    const formatDate = (date: string): string => {
      const d = new Date(date);
      const isValidDate = !isNaN(d.getTime());
      if (!isValidDate) throw new Error('Invalid date format'); // Throw error for invalid date
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      return `${month}/${year}`;
    };

    const ensureValidDate = (timestamp: Date | undefined) => {
      return timestamp ? new Date(timestamp).toISOString() : undefined
    }
    return {
      vin: formData.vin,
      vehicleRegistrationNumber: formData.vehicleRegistrationNumber,
      vehicleMakerId: formData.vehicleMaker,
      vehicleModelId: formData.vehicleModelId,
      modelYear: formatDate(formData.modelyear),
      ownerName: formData.ownerName,
      nationality: formData.nationality,
      vehicleOriginId: formData.vehicleOriginId,
      color: formData.color,
      paintingTypeId: formData.paintingType,
      engineCapacity: formData.engineCapacity.toString(),
      emptyWeight: formData.emptyWeight.toString(),
      maximumLoad: formData.maximumLoad.toString(),
      yearOfManufacture: formatDate(formData.yearOfManufacture),
      numberofPassangers: formData.numberofPassangers.toString(),
      numberofAxes: formData.numberofAxes.toString(),
      basicBaseChasisNumber: formData.basicBaseNumberChasis,
      engineSerialNumber: formData.serialNumber,

      // Throw error if any date is invalid
      licenceValidityStartDate: ensureValidDate(formData.licenceValidityStartDate),
      licenceValidityEndDate: ensureValidDate(formData.licenceValidityEndDate),
      dateOfFirstRegistration: ensureValidDate(formData.dateOfFirstRegistration),

      licensingAuthority: formData.licensingAuthority,
      mortgage: formData.mortgage,
      additionalNotes: formData.additionalNotes,
      address: formData.vehicleOwnerAddress,
      city: formData.city,
      stateId: formData.stateId,
      zip: formData.pincode.toString(),
      countryId: formData.countryId,
      odoMeterReadingDate: ensureValidDate(formData.odoReadingDate),
      odoMeterReadingDateInKM: formData.odoReading,
    };
  }

  convertToDate(modelYear: string): Date {
    const [month, year] = modelYear.split('/');
    return new Date(+year, +month - 1);
  }
  formatToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  populateFormWithServiceSeekersDetails(vehicleDetail: any) {

    if (!vehicleDetail) {
      console.error('Vehicle details not available in the response.');
      return;
    }
    if (!this.isFOrmPopulated) {
      this.model = {
        vehicleRegistrationNumber: vehicleDetail.vehicleRegistrationNumber,
        ownerName: vehicleDetail.ownerName,
        nationality: vehicleDetail.nationality,
        countryId: vehicleDetail.country?.countryId,
        stateId: vehicleDetail.state?.stateId,
        city: vehicleDetail.city,
        vehicleOwnerAddress: vehicleDetail.address,
        pincode: vehicleDetail.zip,
        vin: vehicleDetail.vin,
        vehicleMaker: vehicleDetail.vehicleMaker?.vehicleMakerId,
        vehicleModelId: vehicleDetail.vehicleModel?.vehicleModelId,
        modelyear: this.convertToDate(vehicleDetail.modelYear),
        vehicleOriginId: vehicleDetail.vehicleOrigin?.vehicleOriginId,
        color: vehicleDetail.color,
        paintingType: vehicleDetail.paintingType?.paintingTypeId,
        engineCapacity: vehicleDetail.engineCapacity,
        emptyWeight: vehicleDetail.emptyWeight,
        maximumLoad: vehicleDetail.maximumLoad,
        yearOfManufacture: this.convertToDate(vehicleDetail.yearOfManufacture),
        numberofPassangers: vehicleDetail.numberofPassangers,
        numberofAxes: vehicleDetail.numberofAxes,
        basicBaseNumberChasis: vehicleDetail.basicBaseChasisNumber,
        serialNumber: vehicleDetail.engineSerialNumber,
        licenceValidityStartDate: this.formatToMMDDYYYY(vehicleDetail.licenceValidityStartDate),
        licenceValidityEndDate: this.formatToMMDDYYYY(vehicleDetail.licenceValidityEndDate),
        additionalNotes: vehicleDetail.additionalNotes,
        mortgage: vehicleDetail.mortgage,
        licensingAuthority: vehicleDetail.licensingAuthority,
        dateOfFirstRegistration: vehicleDetail.dateOfFirstRegistration ? this.formatToMMDDYYYY(vehicleDetail.dateOfFirstRegistration) : '',
        odoReadingDate: vehicleDetail.odoMeterReadingDate ? this.formatToMMDDYYYY(vehicleDetail.odoMeterReadingDate) : '',
        odoReading: vehicleDetail.odoMeterReadingDateInKM || '',
      };
      console.log(vehicleDetail.modelYear)
      this.form.patchValue(this.model);
      this.isFOrmPopulated = true
      this.formStateService.setFormData('vehicle-details', this.model);
      this.cdr.detectChanges();
    }

  }
}
