import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-battery-details',
  templateUrl: './battery-details.component.html',
  styleUrl: '../user-details.component.scss'
})
export class BatteryDetailsComponent {
  @Input() user: any
}
