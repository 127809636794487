import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '../../../../Service/User/user.service';
import { UserFormStateService } from '../../Service/formStateService';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-add-tyre-battery-details',
  templateUrl: './add-tyre-battery-details.component.html',
  styleUrls: ['./add-tyre-battery-details.component.scss'],
})
export class AddTyreBatteryDetailsComponent implements OnInit, OnDestroy {
  countryId: any;
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  weeks: { label: string; value: string }[] = [];
  years: { label: string; value: string }[] = [];

  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  isFormPopulated: boolean = false;

  receivedObject: any;
  constructor(
    private api: UserService,
    private formStateService: UserFormStateService,
    private serviceAPI: ServiceProviderService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadFormData();
    this.initializeField();
    this.generateDateCodes();
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  saveFormData() {
    const transformedData = this.transformFormData(this.form.value);
    this.formStateService.setTyreBatteryDetails(transformedData);
    this.formStateService.setFormData('tyre-battery', this.form.value);
    console.log('Saved form data:', this.form.value);
  }

  loadFormData() {
    const savedData = this.formStateService.getFormData('tyre-battery');
    if (savedData) {
      this.model = savedData;
      this.form.patchValue(savedData);
      this.isFormPopulated = true;
      console.log('Loaded saved data:', savedData);
    }
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }
  generateDateCodes() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 5;

    // Generate weeks from 01 to 52
    for (let week = 1; week <= 52; week++) {
      const weekStr = week.toString().padStart(2, '0');
      this.weeks.push({ label: `${weekStr}`, value: weekStr });
    }

    // Generate years from startYear to currentYear
    for (let year = startYear; year <= currentYear; year++) {
      this.years.push({ label: `${year}`, value: `${year}` });
    }
  }

  initializeField(): void {
    this.fields = [
      ...this.frh(),
      ...this.flh(),
      ...this.rrh(),
      ...this.rhl(),
      ...this.spareParts(),
      ...this.batteryDetails(),
      ...this.engineOilDetails(),
    ];
  }

  frh(): FormlyFieldConfig[] {
    return [
      {
        template: '<div class="font-semibold">Tyre details</div>',
      },
      {
        template:
          '<div class="font-semibold text-base mt-6 mb-4 text-500">FRH</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'frhspecification',
            type: 'input',
            templateOptions: {
              label: 'Specification',
              placeholder: 'Enter FRH specification',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'frhBrandName',
            type: 'input',
            templateOptions: {
              label: 'Brand name',
              placeholder: 'Enter FRH brand',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'frhType',
            type: 'select',
            templateOptions: {
              options: this.api.getTyreTypes(),
              label: 'Type',
              placeholder: 'Enter FRH type',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'tyreTypeId',
              labelProp: 'name',
              change: (field, $event) => {
                console.log('Selected tyre type:', $event.value);
              },
            },
          },
          {
            className: 'field col-2',
            key: 'frhWeek',
            type: 'select',
            templateOptions: {
              options: this.weeks,
              label: 'Week',
              placeholder: 'Select week',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'frhYear',
            type: 'select',
            templateOptions: {
              options: this.years,
              label: 'Year',
              placeholder: 'Select year',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }

  flh(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4 text-500">FLH</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'flhSpecification',
            type: 'input',
            templateOptions: {
              label: 'Specification',
              placeholder: 'Enter FLH specification',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'flhBrandName',
            type: 'input',
            templateOptions: {
              label: 'Brand name',
              placeholder: 'Enter FLH brand',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'flhType',
            type: 'select',
            templateOptions: {
              options: this.api.getTyreTypes(),
              label: 'Type',
              placeholder: 'Enter FLH type',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'tyreTypeId',
              labelProp: 'name',
              change: (field, $event) => {
                console.log('Selected tyre type:', $event.value);
              },
            },
          },
          {
            className: 'field col-2',
            key: 'flhWeek',
            type: 'select',
            templateOptions: {
              options: this.weeks,
              label: 'Week',
              placeholder: 'Select week',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'flhYear',
            type: 'select',
            templateOptions: {
              options: this.years,
              label: 'Year',
              placeholder: 'Select year',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }

  rrh(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4 text-500">RRH</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'rrhSpecification',
            type: 'input',
            templateOptions: {
              label: 'Specification',
              placeholder: 'Enter RRH specification',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'rrhBrandName',
            type: 'input',
            templateOptions: {
              label: 'Brand name',
              placeholder: 'Enter RRH brand',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'rrhType',
            type: 'select',
            templateOptions: {
              options: this.api.getTyreTypes(),
              label: 'Type',
              placeholder: 'Enter RRH type',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'tyreTypeId',
              labelProp: 'name',
            },
          },
          {
            className: 'field col-2',
            key: 'rrhWeek',
            type: 'select',
            templateOptions: {
              options: this.weeks,
              label: 'Week',
              placeholder: 'Select week',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'rrhYear',
            type: 'select',
            templateOptions: {
              options: this.years,
              label: 'Year',
              placeholder: 'Select year',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }

  rhl(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4 text-500">RHL</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'rhlSpecification',
            type: 'input',
            templateOptions: {
              label: 'Specification',
              placeholder: 'Enter RHL specification',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'rhlBrandName',
            type: 'input',
            templateOptions: {
              label: 'Brand name',
              placeholder: 'Enter RHL brand',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'rhlType',
            type: 'select',
            templateOptions: {
              options: this.api.getTyreTypes(),
              label: 'Type',
              placeholder: 'Enter RHL type',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'tyreTypeId',
              labelProp: 'name',
            },
          },
          {
            className: 'field col-2',
            key: 'rhlWeek',
            type: 'select',
            templateOptions: {
              options: this.weeks,
              label: 'Week',
              placeholder: 'Select week',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'rhlYear',
            type: 'select',
            templateOptions: {
              options: this.years,
              label: 'Year',
              placeholder: 'Select year',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }

  spareParts(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4 text-500">Spare Parts</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'sparePartSpecification',
            type: 'input',
            templateOptions: {
              label: 'Specification',
              placeholder: "Enter spare part's specification",
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'sparePartBrandName',
            type: 'input',
            templateOptions: {
              label: 'Brand name',
              placeholder: "Enter spare part's brand",
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'sparePartType',
            type: 'select',
            templateOptions: {
              options: this.api.getTyreTypes(),
              label: 'Type',
              placeholder: "Select spare part's type",
              required: true,
              hideRequiredMarker: true,
              valueProp: 'tyreTypeId',
              labelProp: 'name',
            },
          },
          {
            className: 'field col-2',
            key: 'sparePartWeek',
            type: 'select',
            templateOptions: {
              options: this.weeks,
              label: 'Week',
              placeholder: 'Select week',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'sparePartYear',
            type: 'select',
            templateOptions: {
              options: this.years,
              label: 'Year',
              placeholder: 'Select year',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }

  batteryDetails(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4">Battery details</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'batteryMake',
            type: 'select',
            templateOptions: {
              options: this.api.getBatteryMakers(),
              label: 'Battery make',
              placeholder: 'Selected battery make',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'batteryMakerId',
              labelProp: 'name',
            },
          },
          {
            className: 'field col-5',
            key: 'batteryModel',
            type: 'input',
            templateOptions: {
              label: 'Battery model',
              placeholder: 'Enter battery model',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'batteryManufactureDate',
            type: 'custom-dt',
            templateOptions: {
              label: 'Battery mfg',
              placeholder: 'Select battery manufacture date',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5',
            key: 'batteryPurchasedDate',
            type: 'custom-dt',
            templateOptions: {
              label: 'Battery purchased on',
              placeholder: 'Select a battery purchase date',
              required: true,
              hideRequiredMarker: true,
            },
          },
        ],
      },
    ];
  }
  engineOilDetails(): FormlyFieldConfig[] {
    return [
      {
        template:
          '<div class="font-semibold text-base mt-5 mb-4">Engine oil details</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'oilGrade',
            type: 'select',
            templateOptions: {
              options: this.api.getOilGrade(),
              label: 'Engine Oil Grade',
              placeholder: 'Select a option',
              required: true,
              hideRequiredMarker: true,
              valueProp: 'engineOilGradeId',
              labelProp: 'name',
            },
          },
        ],
      },
    ];
  }
  transformFormData(formData: any): any {
    const combineDateCode = (week: string, year: string) => {
      return `${week}/${year}`;
    };

    return {
      tyreDetails: [
        {
          position: 1,
          specification: formData.frhspecification,
          brandName: formData.frhBrandName,
          dateCode: combineDateCode(formData.frhWeek, formData.frhYear),
          tyreTypeId: formData.frhType,
        },
        {
          position: 2,
          specification: formData.flhSpecification,
          brandName: formData.flhBrandName,
          dateCode: combineDateCode(formData.flhWeek, formData.flhYear),
          tyreTypeId: formData.flhType,
        },
        {
          position: 3,
          specification: formData.rrhSpecification,
          brandName: formData.rrhBrandName,
          dateCode: combineDateCode(formData.rrhWeek, formData.rrhYear),
          tyreTypeId: formData.rrhType,
        },
        {
          position: 4,
          specification: formData.rhlSpecification,
          brandName: formData.rhlBrandName,
          dateCode: combineDateCode(formData.rhlWeek, formData.rhlYear),
          tyreTypeId: formData.rhlType,
        },
        {
          position: 5,
          specification: formData.sparePartSpecification,
          brandName: formData.sparePartBrandName,
          dateCode: combineDateCode(
            formData.sparePartWeek,
            formData.sparePartYear
          ),
          tyreTypeId: formData.sparePartType,
        },
      ],
      batteryMakerId: formData.batteryMake?.value ?? formData.batteryMake,
      batteryModel: formData.batteryModel,
      batteryMfg: new Date(formData.batteryManufactureDate),
      batteryPurchasedOn: new Date(formData.batteryPurchasedDate),
      engineOilGradeId: formData.oilGrade,
    };
  }
  onSubmit() {
    this.saveFormData();
    const transformedData = this.transformFormData(this.form.value);
    this.formStateService.setTyreBatteryDetails(transformedData);
    console.log('Transformed data:', transformedData);
    this.nextSection.emit();
  }
  
  populateFormWithServiceSeekersDetails(res: any) {
    if(!this.isFormPopulated){
    const tyreDetails = res?.tyreDetails || [];

    // Populate FRH tyre details
    this.model.frhspecification = tyreDetails[0]?.specification || '';
    this.model.frhBrandName = tyreDetails[0]?.brandName || '';
    this.model.frhType = tyreDetails[0]?.tyreType?.tyreTypeId || '';
    const frhDateCode = tyreDetails[0]?.dateCode ? tyreDetails[0]?.dateCode.split('/') : ['', ''];
    this.model.frhWeek = frhDateCode[0] || '';
    this.model.frhYear = frhDateCode[1] || '';
  
    // Populate FLH tyre details
    this.model.flhSpecification = tyreDetails[1]?.specification || '';
    this.model.flhBrandName = tyreDetails[1]?.brandName || '';
    this.model.flhType = tyreDetails[1]?.tyreType?.tyreTypeId || '';
    const flhDateCode = tyreDetails[1]?.dateCode ? tyreDetails[1]?.dateCode.split('/') : ['', ''];
    this.model.flhWeek = flhDateCode[0] || '';
    this.model.flhYear = flhDateCode[1] || '';
  
    // Populate RRH tyre details
    this.model.rrhSpecification = tyreDetails[2]?.specification || '';
    this.model.rrhBrandName = tyreDetails[2]?.brandName || '';
    this.model.rrhType = tyreDetails[2]?.tyreType?.tyreTypeId || '';
    const rrhDateCode = tyreDetails[2]?.dateCode ? tyreDetails[2]?.dateCode.split('/') : ['', ''];
    this.model.rrhWeek = rrhDateCode[0] || '';
    this.model.rrhYear = rrhDateCode[1] || '';
  
    // Populate RHL tyre details
    this.model.rhlSpecification = tyreDetails[3]?.specification || '';
    this.model.rhlBrandName = tyreDetails[3]?.brandName || '';
    this.model.rhlType = tyreDetails[3]?.tyreType?.tyreTypeId || '';
    const rhlDateCode = tyreDetails[3]?.dateCode ? tyreDetails[3]?.dateCode.split('/') : ['', ''];
    this.model.rhlWeek = rhlDateCode[0] || '';
    this.model.rhlYear = rhlDateCode[1] || '';
  
    // Populate Spare tyre details
    this.model.sparePartSpecification = tyreDetails[4]?.specification || '';
    this.model.sparePartBrandName = tyreDetails[4]?.brandName || '';
    this.model.sparePartType = tyreDetails[4]?.tyreType?.tyreTypeId || '';
    const spareDateCode = tyreDetails[4]?.dateCode ? tyreDetails[4]?.dateCode.split('/') : ['', ''];
    this.model.sparePartWeek = spareDateCode[0] || '';
    this.model.sparePartYear = spareDateCode[1] || '';
  
    // Populate battery details
    this.model.batteryMake = res?.batteryDetails?.batteryMake || '';
    this.model.batteryModel = res?.batteryDetails?.batteryModel || '';
    this.model.batteryManufactureDate = res?.batteryDetails?.batteryManufactureDate
      ? new Date(res?.batteryDetails?.batteryManufactureDate)
      : '';
    this.model.batteryPurchasedDate = res?.batteryDetails?.batteryPurchasedDate
      ? new Date(res?.batteryDetails?.batteryPurchasedDate)
      : '';
  
    // Populate engine oil details
    this.model.oilGrade = res?.engineOilDetails?.oilGrade || '';
  
    this.form.patchValue(this.model);
    this.cdr.detectChanges();
  
    this.isFormPopulated = true;
    this.formStateService.setFormData('tyre-battery', this.model);
  }else{
    console.log('Form already populated, skipping patching')
  }
  }
  
}
