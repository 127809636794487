<div class="main-part" id="section0">
    <div>
        <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;" *ngIf="hasRTOProof">View RTO</p>
        <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;" *ngIf="hasMulkiyaproof">View Mulkiya</p>
        <div class="flex justify-content-center flex-wrap">
            <div class="flex align-items-center justify-content-center">
                <div *ngIf="hasRTOProof">
                    <img [src]="getDocumentUrl(getDocumentByType(9))" alt="RTO Document"
                        style="max-width: 470px; max-height: 275px; width: auto; height: auto;" class="image-container"
                        (error)="onImageError($event)" />
                </div>
                <div *ngIf="hasMulkiyaproof">
                    <img [src]="getDocumentUrl(getDocumentByType(10))" alt="Mulkiya Document"
                        style="max-width: 470px; max-height: 275px; width: auto; height: auto;" class="image-container"
                        (error)="onImageError($event)" />
                </div>
            </div>
        </div>
    </div>
</div>