import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '../../../../Service/User/user.service';
import { UserFormStateService } from '../../Service/formStateService';
import { ServiceAdvisorFormStateService } from '../../../service-advisors/service/service-advisor-formStateService';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {
  countryId: any;
  form = new FormGroup({});
  model: any = {};
  customerId: any
  editMode=false
  fields: FormlyFieldConfig[] = [];
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  receivedObject: any;

  constructor(
    private api: UserService,
    private formStateService: UserFormStateService,
    private fileService: ServiceAdvisorFormStateService,
  ) { }

  ngOnInit(): void {
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      this.initializeFields();
    }
    console.log('Initializing ServiceAdvisorResumeComponent');
    if (this.receivedObject) {
      this.populateFormWithServiceProviderDetails(this.receivedObject);
    }
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    const fileSourceId = 2;
    this.fields = [
      {
        key: 'file',
        type: 'file-upload1',
        templateOptions: {
          label: 'File Upload',
          placeholder: 'Upload a file',
          required: true,
          accept: '.pdf,.jpg,.png',
          fileSourceId,
        },
      },
    ];
  }
  patchFormWithFile(fileName: string, fileUrl: string, fileId: number) {
    // Patch form with file data
    this.form.patchValue({ file: fileUrl });

    // Update formStateService with file information
    this.fileService.setFormData('resumeFile', {
      fileName: fileName,
      fileUrl: fileUrl,
      fileId: fileId,
    });
    this.fileService.setUploadedFileId('resumeFile', fileId);
  }
  populateFormWithServiceProviderDetails(vehicleDetails: any) {
    const RTODocument = vehicleDetails?.documents?.find(
      (doc: any) => { return doc.documentTypeId === 9 }
    );

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');

    if (!uploadedFileId && RTODocument) {
      const { fileName, filePath: fileUrl, fileId } = RTODocument

      this.patchFormWithFile(fileName, fileUrl, fileId);
    } else {
      console.log('Skipping backend patch, uploaded file ID exists:', uploadedFileId);
    }
  }

  saveFormData() {
    const formData: any = { ...this.form.value };

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');
    console.log('Uploaded file id ::', uploadedFileId);

    if (uploadedFileId) {
      const documentTypeId = this.countryId == '1' ? 9 : 10;
      const uploadDocs = {
        documents: [
          {
            documentTypeId,
            fileId: uploadedFileId,
          },
        ],
      };
      this.formStateService.setUploadDocs(uploadDocs);
      this.fileService.setFormData('upload-documents', formData);
    } else {
      console.error('No uploaded file ID found. Please ensure the file is uploaded.');
    }
  }

  loadFormData() {
    const savedData = this.fileService.getFormData('upload-documents');
    if (savedData) {
      const { fileName, fileUrl, file } = savedData;
      console.log('Loaded saved data:', savedData);
      this.patchFormWithFile(fileName, fileUrl, file);
    }
  }

  onSubmit() {
    this.saveFormData();
    this.nextSection.emit();
    const data = this.formStateService.getUserCombinedData();
    console.log('Transformed data from last form:', data);
  }
}
