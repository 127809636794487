import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormStateService } from '../../../service-provider/add-sp-new-component/form-state.service';

@Component({
  selector: 'app-user-pricing-plan',
  templateUrl: './user-pricing-plan.component.html',
  styleUrl: './user-pricing-plan.component.scss'
})
export class UserPricingPlanComponent {
  @Input() form!: FormGroup;
  @Output() planSelected = new EventEmitter<any>();
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();

  selectedPlan: string | null = null;
  subscriptionForm: FormGroup;

  plans = [
    { name: 'Standard Plan', price: 2000.00, description: 'One time fee, No recurring payments', features: ['Access to all basic features'], unavailableFeatures: ['Diagnostic tool'] },
    { name: 'Basic Plan', price: 200.00, description: 'One time fee, No recurring payments', features: ['Access to all basic features', 'Diagnostic Tool'], unavailableFeatures: [] },
    { name: 'Premium Plan', price: 600.00, description: 'One time fee, No recurring payments', features: ['Access to all basic features', 'Diagnostic Tool'], unavailableFeatures: [] }
  ];

  constructor(private fb: FormBuilder,
    private formStateService: FormStateService
  ) {
    this.subscriptionForm = this.fb.group({
      selectedPlan: [''],
      plan: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    const savedPlan = this.formStateService.getFormData('selectedPlan');
    if (savedPlan) {
      this.selectedPlan = savedPlan;
      this.subscriptionForm.get('selectedPlan')?.setValue(savedPlan);
    }
    this.form.statusChanges.subscribe(() => {
      this.formValidity.emit(this.form.valid);
    });
  }

  selectPlan(plan: string): void {
    this.selectedPlan = plan;
    this.subscriptionForm.get('selectedPlan')?.setValue(plan);
    this.planSelected.emit(this.plans.find(p => p.name === plan));
    this.formStateService.setFormData('selectedPlan', plan);
  }
  emitPlan(){
    
  }
  onSubmit(): void {
    if (this.selectedPlan) {
      console.log('Selected Plan:', this.selectedPlan);
      this.planSelected.emit(this.plans.find(p => p.name === this.selectedPlan));
      this.selectPlan(this.selectedPlan);
      this.nextSection.emit()
    } else {
      console.log('No plan selected');
    }
  }
}
