<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Additional Vehicle Details</p>
    <div class="flex flex-row flex-wrap">
        <div style="width: 225px;">
            <p class="business-type-label">Engine Capacity</p>
            <p class="business-type-value">{{ user?.engineCapacity }}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Empty Weight</p>
            <p class="business-type-value">{{ user?.emptyWeight }}</p>
        </div>
        <div>
            <div style="width: 255px;padding-left: 52px;">
                <p class="business-type-label">Maximum load</p>
                <p class="business-type-value">{{ user?.maximumLoad }}</p>
            </div>
        </div>
    </div>
    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
        <div style="width: 225px;">
            <p class="business-type-label">Year of Manifacture</p>
            <p class="business-type-value">{{ user?.yearOfManufacture }}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Number of Passenger</p>
            <p class="business-type-value">2</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Number of axes</p>
            <p class="business-type-value">2</p>
        </div>
    </div>
    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
        <div style="width: 225px;">
            <p class="business-type-label">Basic base number chasis</p>
            <p class="business-type-value">{{ user?.basicBaseChasisNumber}}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Engine serial number</p>
            <p class="business-type-value">{{ user?.engineSerialNumber}}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Engine oil grade</p>
            <p class="business-type-value">{{ user?.engineOilGrade?.name }}</p>
        </div>
    </div>
    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
        <div style="width: 225px;">
            <p class="business-type-label">Odometer Reading date</p>
            <p class="business-type-value">{{ user?.odoMeterReadingDate|date}}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Odometer reading</p>
            <p class="business-type-value">{{ user?.odoMeterReadingDateInKM}}Km</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">License validity</p>
            <p class="business-type-value">{{ user?.licenceValidityEndDate|date }}</p>
        </div>
    </div>
    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
        <div style="width: 225px;">
            <p class="business-type-label">Date of first registration</p>
            <p class="business-type-value">{{ user?.dateOfFirstRegistration|date}}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Licensing authority</p>
            <p class="business-type-value">{{ user?.licensingAuthority}}</p>
        </div>
        <div style="width: 255px;padding-left: 52px;">
            <p class="business-type-label">Mortgage (if any)</p>
            <p class="business-type-value">{{ user?.mortgage }}</p>
        </div>
    </div>
    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
        <div class="mb-8">
            <p class="business-type-label">Notes</p>
            <p class="business-type-value">{{ user?.additionalNotes }}</p>
        </div>
    </div>
</div>