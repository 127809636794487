<div id="section8">
  <div class="top-container" #target>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex align-items-center justify-content-center m-3">
        <i class="pi pi-arrow-left" style="font-size: 1rem; cursor: pointer" (click)="navigateTo()"></i>
      </div>
      <!-- Page header -->
      <div class="flex align-items-center justify-content-center m-2">
        <span class="m-1" *ngIf="!shouldHideButtons">
          <button class="btn-1 btn-warning-1 outlined-1 small raised" (click)="clickRejectModal()">
            Reject
          </button>
        </span>
        <span class="m-1">
          <button *ngIf="!shouldHideButtons" class="btn-1 btn-warning-1 outlined-2 small raised"
            (click)="openEditModal(user?.customerId)">
            Edit
          </button>
        </span>
        <span class="m-1">
          <div class="container">
            <button class="btn-1 btn-warning-1 outlined-2 small raised" (click)="openReviewModal()">
              Mark as Review
            </button>
          </div>
        </span>
        <span class="m-1" *ngIf="!shouldHideButtons">
          <button class="btn-1 btn-seconary-warning small raised" (click)="openApproveModal()">
            Approve
          </button>
        </span>
        <span class="m-1" *ngIf="showButtons">
          <button class="btn-1 btn-warning-1 outlined-1 small raised">
            Mark as disable
          </button>
        </span>
      </div>
    </div>
    <!-- Section -->
    <div class="card-top">
      <div class="card">
        <div class="card-content">
          <div class="avatar">
            <span *ngIf="user && user.name">
              {{ user.name.charAt(0) }}
              <ng-container *ngIf="user.name.split(' ')[1]">
                {{ user.name.split(" ")[1].charAt(0) }}
              </ng-container>
            </span>
          </div>
          <div class="info">
            <div class="name-status">
              <div class="name">{{ user.name }}</div>
            </div>
            <div class="details">
              <div class="detail">
                <div class="detail-title">Phone</div>
                <div class="detail-content">{{ user.phoneNumber }}</div>
              </div>
              <div class="detail">
                <div class="detail-title">Address</div>
                <div class="detail-content">
                  {{ user.address }}, {{ user.city }}, {{ user.state?.name }},
                  {{ user.country?.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span class="tag" [ngClass]="{
            submitted: user.status === 'Submitted',
            approved: user.status === 'Accepted',
            inactive: user.status === 'Inactive',
            rejected: user.status === 'Rejected'
          }">{{ user.status === "Accepted" ? "Active" : user.status }}</span>
      </div>
    </div>

    <!-- snapshot -->
    <div class="card-top" style="margin-top: 24px">
      <app-snapshot [user]="user"></app-snapshot>
    </div>
  </div>
</div>
<div>
  <!-- View Mulkiya/View RTO -->
  <app-view-mulkiya-rto [hasRTOProof]="hasRTOProof()" [hasMulkiyaproof]="hasMulkiyaproof()"
    [getDocumentUrl]="getDocumentUrl.bind(this)" [getDocumentByType]="getDocumentByType.bind(this)">
  </app-view-mulkiya-rto>
  <div class="flex justify-content-end flex-wrap" (click)="toggleDropdown()">
    <div class="m-2">
      <div class="dropdown" [class.open]="dropdownOpen">
        <button class="dropdown-button">Jump to</button>
        <div class="dropdown-content" *ngIf="dropdownOpen">
          <a *ngFor="let item of items; let i = index" (click)="jumpToSection(i)">{{ item.label }}</a>
        </div>
      </div>
    </div>
  </div>

  <!--Vehicle owner details Sections -->
  <div class="main-part" id="section1">
    <app-vehicle-owner-details [user]="user.vehicleDetail"></app-vehicle-owner-details>
  </div>

  <!--Basic Vehicle details -->
  <div class="main-part" id="section2">
    <app-basic-vehicle-details [user]="user.vehicleDetail">
    </app-basic-vehicle-details>
  </div>

  <!-- Additional vehicle details -->
  <div class="main-part" id="section3">
    <app-additional-vehicle-details [user]="user.vehicleDetail">
    </app-additional-vehicle-details>
  </div>

  <!-- Tyre details -->
  <div class="main-part" id="section4">
    <app-tyre-details [user]="user.vehicleDetail"></app-tyre-details>
  </div>

  <!-- battery details -->
  <div class="main-part" id="section5">
    <app-battery-details [user]="user.vehicleDetail"></app-battery-details>
  </div>

  <!-- Insurance details -->
  <div class="main-part" id="section6">
    <app-insurance-details [user]="user.vehicleDetail"></app-insurance-details>
  </div>
  <!-- History -->
  <div class="main-part" id="section7">
    <p-timeline [value]="events" layout="horizontal" align="top">
      <ng-template pTemplate="content" let-event>
        {{ event.status }}
      </ng-template>
    </p-timeline>
  </div>
</div>
<p-dialog [(visible)]="reviewModal">
  <ng-template pTemplate="headless">
    <app-review-modal (closeModal)="closeReviewModal()" [resourceType]="'USER'" [resourceTypeLabel]="'User'"
      (onReviewSuccess)="markForReview()"></app-review-modal>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="rejectModal">
  <ng-template pTemplate="headless">
    <app-reject-modal (closeModal)="closeRejectModal()" [resourceType]="'USER'" [resourceTypeLabel]="'User'"
      (reject)="reject()"></app-reject-modal>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="visibleApproveModal">
  <ng-template pTemplate="headless">
    <app-custom-dialog (closeModal)="closeApproveModal()" [title]="'Approve service seeker application?'"
      [message]="'Once you approve,'" [details]="[
        'This service seeker application will be moved to the \<span style=\'color: #101828;font-weight: 700;\'>approved \</span> queue.'
      ]" (onApprove)="approve()" [resourceType]="'USER'"></app-custom-dialog>
  </ng-template>
</p-dialog>
<div>
  <app-add-user *ngIf="showModal" [id]="idToEdit" (closeDialog)="showModalW()"></app-add-user>
</div>