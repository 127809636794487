<div>
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Vehicle Owner Details</p>
    <div class="flex flex-row flex-wrap mb-8">
        <div style="width: 225px;">
            <p class="business-type-label">Owner Name</p>
            <p class="business-type-value">{{ user?.ownerName }}</p>
        </div>
        <div style="width: 255px; padding-left: 52px;">
            <p class="business-type-label">Nationality</p>
            <p class="business-type-value">{{ user?.nationality }}</p>
        </div>
        <div style="width: 225px; padding-left: 52px;">
            <p class="business-type-label">Address</p>
            <p class="business-type-value">{{ user?.address }},
                {{ user?.zip }},{{user?.state?.name}},{{ user?.country?.name }}
            </p>
        </div>
    </div>
</div>