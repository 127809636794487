import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tyre-details',
  templateUrl: './tyre-details.component.html',
  styleUrl: '../user-details.component.scss'
})
export class TyreDetailsComponent {
 @Input() user:any
}
