import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrl: '../user-details.component.scss'
})
export class SnapshotComponent {

  @Input() user:any
}
