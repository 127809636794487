import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../Service/User/user.service';
import { FormStateService } from '../../service-provider/add-sp-new-component/form-state.service';
import { UserFormStateService } from '../Service/formStateService';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  user: any = {};
  userId!: any;
  visibleViewer = false;
  showDocumentViewer = false;
  selectedDocumentUrl: string | undefined;
  userDocuments: any[] = [];
  hasVehicleRegistrationProof = false;
  hasMulkiyaProof = false;

  isOpen = false;
  rejectModal: any = false;
  reviewModal: any = false;
  visibleApproveModal: any = false;
  currentSection = 0;
  dropdownOpen = false;
  items: Array<{ label: string; index: number }> = [];
  events: any[] | undefined;

  showModal = false;
  idToEdit!: any;
  showButtons: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: UserService,
    private formStateService: FormStateService,
    private userFormStateService: UserFormStateService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('customerId');
      this.formStateService.setUserId(this.userId);
      this.userFormStateService.setUserId(this.userId);
      this.getUsersDetails();
      this.getStatusChangeHistory();
    });
  }

  getStatusChangeHistory() {
    this.api.getUserStatusHistory(this.userId).subscribe((data: any) => {
      this.events = data;
    });
  }
  openReviewModal() {
    this.reviewModal = true;
  }
  closeReviewModal() {
    this.reviewModal = false;
  }
  markForReview() {
    this.reviewModal = false;
  }
  getUsersDetails() {
    if (this.userId) {
      this.api.getUserById(this.userId).subscribe(
        (res: any) => {
          this.user = res;
          this.userDocuments = res.vehicleDetail?.documents || [];
          console.log('User Documents:', this.userDocuments); // Log the documents to inspect the filePath
          this.checkDocumentPresence();
          this.updateDropdownItems();
          console.log(this.user);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  updateDropdownItems() {
    this.items = [];
    if (this.hasRTOProof()) {
      this.items.push({ label: 'View RTO', index: 0 });
    }
    if (this.hasMulkiyaproof()) {
      this.items.push({ label: 'View Mulkiya', index: 0 });
    }
    this.items.push(
      { label: 'Vehicle owner details', index: 1 },
      { label: 'Basic vehicle details', index: 2 },
      { label: 'Additional vehicle details', index: 3 },
      { label: 'Tyre details', index: 4 },
      { label: 'Battery details', index: 5 },
      { label: 'Insurance details', index: 6 },
      { label: 'History', index: 7 },
      { label: 'Top', index: 8 }
    );
  }

  jumpToSection(index: number) {
    this.currentSection = index;
    setTimeout(() => {
      const el = document.getElementById(`section${index}`);
      el?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
    this.dropdownOpen = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.dropdown')) {
      this.dropdownOpen = false;
    }
  }

  checkDocumentPresence() {
    this.hasVehicleRegistrationProof = this.userDocuments.some(
      (doc) => doc.documentTypeId === 9
    );
    this.hasMulkiyaProof = this.userDocuments.some(
      (doc) => doc.documentTypeId === 10
    );
  }

  approve() {
    this.getUsersDetails();
  }

  reject() {
    this.getUsersDetails();
  }

  navigateTo() {
    this.router.navigate(['/service-seekers']);
  }

  closeViewer() {
    this.visibleViewer = false;
  }

  viewer() {
    this.visibleViewer = true;
  }

  viewDocumentById(documentTypeId: number) {
    const document = this.userDocuments.find(
      (doc) => doc.documentTypeId === documentTypeId
    );
    if (document) {
      this.selectedDocumentUrl = `${this.api.api}${document.filePath}`;
      this.showDocumentViewer = true;
      this.viewer();
    } else {
      console.error(
        `Document with documentTypeId ${documentTypeId} not found.`
      );
    }
  }

  hasRTOProof(): boolean {
    return this.user?.vehicleDetail?.documents?.some(
      (doc: any) => doc.documentTypeId === 9
    );
  }

  hasMulkiyaproof(): boolean {
    return this.user?.vehicleDetail?.documents?.some(
      (doc: any) => doc.documentTypeId === 10
    );
  }

  getDocumentByType(documentTypeId: number): any {
    return this.user?.vehicleDetail?.documents?.find(
      (doc: any) => doc.documentTypeId === documentTypeId
    );
  }

  getDocumentUrl(document: any): string {
    if (!document || !document.filePath) {
      return '';
    }
    const correctedFilePath = document.filePath.startsWith('/')
      ? document.filePath
      : `/${document.filePath}`;
    const url = `${this.api.api}${correctedFilePath}`;
    return url;
  }

  get shouldHideButtons(): boolean {
    return (
      this.user.status === 'Rejected' ||
      this.user.status === 'Approved' ||
      this.user.status === 'Active'
    );
  }

  get showRejectButton(): boolean {
    return this.user.status === 'Submitted';
  }

  get showEditButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  get showMarkAsReviewButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  get showApproveButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  clickRejectModal(): void {
    this.rejectModal = true;
  }

  closeRejectModal(): void {
    this.rejectModal = false;
  }

  openApproveModal() {
    this.visibleApproveModal = true;
  }

  closeApproveModal() {
    this.visibleApproveModal = false;
  }
  showModalW() {
    this.showModal = false;
    this.getUsersDetails();
  }
  openEditModal(id: any) {
    this.idToEdit = id;
    this.showModal = true;
    console.log(this.idToEdit);
  }
}
