<div style="margin-bottom: 43px" class="font-semibold text-xl mt-5">
  Payment
</div>
<div>
  <p class="plan-details-title">Plan details</p>
</div>
<div class="plan-details-box">
  <div class="plan-details-content">
    <div class="plan-icon">
      <i class="pi pi-bolt"></i>
    </div>
    <div class="plan-name">
      <p>{{ selectedPlan?.name }}</p>
    </div>
    <div class="plan-price">
      <p>
        <span class="mr-3 label"
          >₹{{ selectedPlan?.price }} x 12
          <span style="font-size: 16px; font-weight: 800">=</span>
        </span>
        <span class="label"
          >₹{{ selectedPlan?.price * 12 | number : "1.2-2" }}</span
        >
      </p>
    </div>
  </div>
  <div class="flex justify-content-end flex-wrap">
    <div class="mr-6 label">Tax (5%):</div>
    <div class="label">
      ₹{{ selectedPlan?.price * 12 * taxRate | number : "1.2-2" }}
    </div>
  </div>
  <hr class="divider" />
  <div class="flex justify-content-end flex-wrap">
    <div class="mr-6 label">Total:</div>
    <div class="label">
      ₹{{
        selectedPlan?.price * 12 + selectedPlan?.price * 12 * taxRate
          | number : "1.2-2"
      }}
    </div>
  </div>
</div>
<div class="payment-method">
  <p class="payment-method-title">Choose Payment Method</p>
  <div class="payment-method-options">
    <div class="payment-method-option">
      <div style="margin-top: 6px; margin-bottom: 43px">
        <div class="box-2">
          <input
            type="radio"
            id="offline"
            name="paymentMethod"
            (change)="selectPaymentMethod('offline')"
            style="margin-right: 16px"
          />
          <label for="offline" style="font-size: 16px"
            >Paid to representative (offline)</label
          >
        </div>
        <div class="box-2 mt-2">
          <input
            type="radio"
            id="online"
            name="paymentMethod"
            (change)="selectPaymentMethod('online')"
            style="margin-right: 16px"
          />
          <label for="online" style="font-size: 16px">Pay via online</label>
        </div>
      </div>
    </div>
  </div>
</div>
<button type="submit" [disabled]="!paymentMethod" class="custom-upload-button">
  Finish
  <span class="arrow">➔</span>
</button>
