import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-mulkiya-rto',
  templateUrl: './view-mulkiya-rto.component.html',
  styleUrl: '../user-details.component.scss'
})
export class ViewMulkiyaRtoComponent {
  @Input() hasRTOProof!: boolean;
  @Input() hasMulkiyaproof!: boolean;
  @Input() getDocumentUrl!: (doc: any) => string;
  @Input() getDocumentByType!: (type: number) => any;

  onImageError(event: any) {
    console.error('Image failed to load:', event.target.src);
    event.target.style.display = 'none'; 
  }
}