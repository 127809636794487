import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrl: '../user-details.component.scss'
})
export class InsuranceDetailsComponent {

  @Input() user:any
}
