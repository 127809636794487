import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UserFormStateService } from '../Service/formStateService';
import { UserPersonalDetailComponent } from './user-personal-detail/user-personal-detail.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { AddVehicleDetailsComponent } from './add-vehicle-details/add-vehicle-details.component';
import { AddTyreBatteryDetailsComponent } from './add-tyre-battery-details/add-tyre-battery-details.component';
import { AddInsuranceDetailsComponent } from './add-insurance-details/add-insurance-details.component';
import { UserService } from '../../../Service/User/user.service';
import { ServiceAdvisorFormStateService } from '../../service-advisors/service/service-advisor-formStateService';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss',
})
export class AddUserComponent {
  @Input() id?: number;
  editMode: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private formstate: UserFormStateService,
    private api: UserService,
    private fileService: ServiceAdvisorFormStateService
  ) { }

  ngOnInit(): void {
    this.editMode = !!this.id;
    if (this.editMode && this.id) {
      this.serviceSeekersById(this.id);
    }
    this.cdr.detectChanges();
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
    }
  }
  @Output() updateUserList = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<void>();
  @ViewChild(UserPersonalDetailComponent)
  personalDetails!: UserPersonalDetailComponent;

  @ViewChild(UploadDocumentsComponent)
  uploadDocuments!: UploadDocumentsComponent;

  @ViewChild(AddVehicleDetailsComponent)
  vehicleDetails!: AddVehicleDetailsComponent;

  @ViewChild(AddTyreBatteryDetailsComponent)
  tyreBatteryDetails!: AddTyreBatteryDetailsComponent;

  @ViewChild(AddInsuranceDetailsComponent)
  insuranceDetails!: AddInsuranceDetailsComponent;

  sectionValidity: { [key: string]: boolean } = {
    'personal-details': false,
    'upload-section': false,
    'vehicle-details': false,
    'tyre-battery-details': false,
    'insurance-details': false,
    pricing: false,
    payments: false,
  };

  activeSection: string = 'personal-details';
  selectedPlan: any = null;
  countryId!: any;

  setActiveSection(section: string): void {
    this.activeSection = section;
    console.log(this.activeSection);
    if (this.activeSection === 'upload-section' && this.editMode && this.id) {
      this.serviceSeekersById(this.id)
    }
    if (this.activeSection === 'vehicle-details' && this.editMode && this.id) {
      this.serviceSeekersById(this.id)
    }
    if (this.activeSection === 'tyre-battery-details' && this.editMode && this.id) {
      this.serviceSeekersById(this.id)
    }
    if (this.activeSection === 'insurance-details' && this.editMode && this.id) {
      this.serviceSeekersById(this.id)
    }
  }
  onPlanSelected(plan: any): void {
    this.selectedPlan = plan;
  }

  close() {
    if (this.personalDetails) {
      this.personalDetails.resetForm();
      this.cdr.detectChanges();
    }
    if (this.uploadDocuments) {
      this.uploadDocuments.resetForm();
      this.cdr.detectChanges();
    }
    if (this.vehicleDetails) {
      this.vehicleDetails.resetForm();
      this.cdr.detectChanges();
    }
    if (this.tyreBatteryDetails) {
      this.tyreBatteryDetails.resetForm();
      this.cdr.detectChanges();
    }
    if (this.insuranceDetails) {
      this.insuranceDetails.resetForm();
      this.cdr.detectChanges();
    }

    this.formstate.clearFormData();
    this.closeDialog.emit();
  }
  onUpdateUserList(): void {
    this.updateUserList.emit();
  }

  serviceSeekersById(id: number) {
    this.api.getCustommerById(id).subscribe((res: any) => {
      console.log(res.vehicleDetail);
  
      // Documents handling
      const documents = res.vehicleDetail?.documents;
      const files = documents?.find((doc: any) => doc.documentTypeId === 9);
      const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');
      if (!uploadedFileId && files) {
        console.log('Files found:', files);
        this.fileService.setFormData('resumeFile', {
          fileName: files.fileName,
          fileUrl: files.filePath,
          file: files.fileId,
        });
      }
  
      // Personal details patching
      if (this.personalDetails) {
        this.personalDetails.populateFormWithServiceSeekersDetails(res);
      }
  
      // Upload documents patching
      if (this.uploadDocuments) {
        console.log('Service seeker upload document form');
        this.uploadDocuments.populateFormWithServiceProviderDetails(res.vehicleDetail);
        this.cdr.detectChanges();
      }
  
      // Vehicle details patching
      if (this.vehicleDetails) {
        console.log('Service Seeker vehicle upload patching');
        this.vehicleDetails.populateFormWithServiceSeekersDetails(res.vehicleDetail);
      }
  
      // Tyre, battery, and engine oil details patching
      if (this.tyreBatteryDetails) {
        console.log('Service Seeker vehicle tyre, battery, and engine oil details patching');
        
        const tyreDetails = res.vehicleDetail?.tyreDetails;
        const batteryDetails = {
          batteryMake: res.vehicleDetail?.batteryMaker?.batteryMakerId,
          batteryModel: res.vehicleDetail?.batteryModel,
          batteryManufactureDate: res.vehicleDetail?.batteryMfg,
          batteryPurchasedDate: res.vehicleDetail?.batteryPurchasedOn,
        };
        const engineOilDetails = {
          oilGrade: res.vehicleDetail?.engineOilGrade?.engineOilGradeId,
        };
  
        this.tyreBatteryDetails.populateFormWithServiceSeekersDetails({
          tyreDetails,
          batteryDetails,
          engineOilDetails,
        });
      }
  
      // Insurance details patching
      if (this.insuranceDetails) {
        console.log('Service Seeker insurance details patching');
  
        const insuranceDetails = {
          insuranceCompany: res.vehicleDetail?.insuranceCompany?.insuranceCompanyId,
          insurancePolicyNumber: res.vehicleDetail?.insurancePolicyNumber,
          insuranceType: res.vehicleDetail?.insuranceType?.insuranceTypeId,
          insuranceValidTillDate: res.vehicleDetail?.insuranceValidTillDate,
        };
  
        this.insuranceDetails.populateFormWithServiceSeekersDetails(insuranceDetails);
      }
    });
  }
  
  
}
