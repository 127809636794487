import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '../../../../Service/User/user.service';
import { UserFormStateService } from '../../Service/formStateService';
import { MessageService } from 'primeng/api';
import { ToastService } from '../../../../Service/ToastService/toast.service';
import { ServiceAdvisorFormStateService } from '../../../service-advisors/service/service-advisor-formStateService';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { FormStateService } from '../../../service-provider/add-sp-new-component/form-state.service';

@Component({
  selector: 'app-add-insurance-details',
  templateUrl: './add-insurance-details.component.html',
  styleUrls: ['./add-insurance-details.component.scss'],
})
export class AddInsuranceDetailsComponent implements OnInit {
  countryId: any;
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  isLoading: boolean = false;
  isEditMode: boolean = false;
  fileId: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updateUserList: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  receivedObject: any;
  isFormPopulated: boolean = false
  constructor(
    private api: UserService,
    private formStateService: UserFormStateService,
    private fileService: ServiceAdvisorFormStateService,
    private messageService: MessageService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private forServices: FormStateService
  ) { }

  ngOnInit(): void {
    this.loadFormData();
    const customerId = this.forServices.getUserId();
    this.isEditMode = !!customerId;
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      console.log(this.countryId);
    }

    this.initializeField();
    this.api.insuranceDetails$.subscribe((details) => {
      if (details) {
        console.log('Insurance Details:', details);
        this.populateInsuranceDetails(details);
      }
    });
  }
  populateInsuranceDetails(details: any) {
    this.model.insuranceCompany = details.insuranceCompany.insuranceCompanyId;
    this.model.insurancePolicyNumber = details.vehicleInsuranceDetails.policyNo;
    const insuranceUptoDate = details.vehicleInsuranceDetails.insuranceUpto
      ? new Date(details.vehicleInsuranceDetails.insuranceUpto)
      : null;

    if (insuranceUptoDate && !isNaN(insuranceUptoDate.getTime())) {
      this.model.insuranceValidDate = insuranceUptoDate
        .toISOString()
        .substring(0, 10);
    } else {
      this.model.insuranceValidDate = '';
    }

    this.form.patchValue(this.model);
    console.log('Insurance Details Populated:', details.insuranceCompany.name);
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }

  saveFormData() {
    const formData = this.form.value;
    const transformedData = this.transformedData(formData)
    this.formStateService.setdata('insurance-details', formData);
    console.log('Saved form data:', formData);
  }

  loadFormData() {
    const savedData = this.formStateService.getdata('insurance-details');
    if (savedData) {
      console.log('Loaded saved data:', savedData);
      this.model = savedData;
      this.isFormPopulated = true
      this.form.patchValue(savedData);
    }
  }

  initializeField(): void {
    this.fields = [
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'insuranceCompany',
            type: 'select',
            templateOptions: {
              options: this.api.getInsuranceCompanyNames(),
              label: 'Insurance Company Name',
              placeholder: 'Enter insurance Company Name',
              required: true,
              hideRequiredMarker: true,
              disabled: this.isEditMode,
              valueProp: 'insuranceCompanyId',
              labelProp: 'name',
            },
          },
          {
            className: 'field col-5',
            key: 'insurancePolicyNumber',
            type: 'input',
            templateOptions: {
              label: 'Insurance Policy Number',
              placeholder: 'Enter insurance Policy Number',
              required: true,
              hideRequiredMarker: true,
              disabled: this.isEditMode,

            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'insuranceType',
            type: 'select',
            templateOptions: {
              options: this.api.getInsuranceTypes(),
              labelProp: 'name',
              valueProp: 'insuranceTypeId',
              label: 'Insurance Type',
              placeholder: 'Select insurance type',
              required: true,
              hideRequiredMarker: true,
              disabled: this.isEditMode,
            },
          },
          {
            className: 'field col-5',
            key: 'insuranceValidDate',
            type: 'custom-min-dt',
            templateOptions: {
              label: 'Insurance valid till',
              placeholder: 'Select insurance validity date',
              required: true,
              hideRequiredMarker: true,
              disabled: this.isEditMode,
            },
          },
        ],
      },
    ];
  }

  transformedData(formData: any) {
    const toISOString = (timestamp: Date | undefined) => {
      return timestamp ? new Date(timestamp).toISOString() : undefined
    }

    return {
      insuranceCompanyId: formData.insuranceCompany,
      insurancePolicyNumber: formData.insurancePolicyNumber,
      insuranceTypeId: formData.insuranceType,
      insuranceValidTillDate: toISOString(formData.insuranceValidDate),
    };
  }

  clearForm(): void {
    this.formStateService.clearFormData();
  }

  populateFormWithServiceSeekersDetails(res: any) {
    if (!this.isFormPopulated) {
      this.model = {
        insuranceCompany: res.insuranceCompany || '',
        insurancePolicyNumber: res.insurancePolicyNumber || '',
        insuranceType: res.insuranceType || '',
        insuranceValidDate: this.formatToMMDDYYYY(res.insuranceValidTillDate),
      };
      this.form.patchValue(this.model);
      this.cdr.detectChanges();
      this.isFormPopulated = true;
      this.formStateService.setFormData('insurance-details', this.model);
    }

  }
  formatToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  onSubmit(): void {
    if (this.isEditMode) {
      this.updateServiceSeeker();
    } else {
      this.addSeviceSeeker();
    }
  }

  addSeviceSeeker() {
    const formData = this.form.value;
    this.formStateService.setdata('insurance-details', formData);
    const transformedData = this.transformedData(this.form.value);
    this.formStateService.setInsuranceDetails(transformedData);
    const data = this.formStateService.getUserCombinedData();
    console.log(data);
    this.isLoading = true;
    this.api.createNewUser(data).subscribe(
      (response) => {
        this.isLoading = false;
        if (response.isSuccess) {
          this.toastService.showToast(
            'Service Advisor Added Successfully',
            'success'
          );
          setTimeout(() => {
            this.clearForm();
            this.close.emit();
            this.updateUserList.emit();
          }, 1500);
        } else {
          this.handleError(response.errors);
        }
      },
      (error) => {
        this.isLoading = false;
        console.error('Error:', error);
        this.handleError(error.error?.errors);
      }
    );
  }
  updateServiceSeeker(): void {
    this.saveFormData();
    const customerId = this.forServices.getUserId()
    const data = this.formStateService.getUserCombinedData()
    console.log(data)
    this.api
      .updateServiceSeekersInfo(customerId, data)
      .subscribe(
        (res: any) => {
          this.handleSuccess('Service Seeker updated successfully');
        },
        () => {
          this.handleError1(
            'An error occurred while updating. Please try again.'
          );
        }
      );
  }
  handleSuccess(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'success');
    setTimeout(() => {
      this.clearForm();
      this.close.emit();
      this.updateUserList.emit();
    }, 1500);
  }

  handleError1(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'error');
  }

  private handleError(errors: string[]) {
    let errorMessage = 'An unexpected error occurred.';
    if (errors && errors.length > 0) {
      errorMessage = errors.join(', ');
    }
    this.toastService.showToast(errorMessage, 'error');
    this.messageService.add({ severity: 'error', detail: errorMessage });
  }
}
