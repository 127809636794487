import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormStateService } from '../../../service-provider/add-sp-new-component/form-state.service';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrl: './user-payment.component.scss'
})
export class UserPaymentComponent implements OnInit {

  @Input() selectedPlan: any;
  @Input() form!: FormGroup;
  @Output() updateServiceProviderList: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<any> = new EventEmitter()
  @Output() formValidity = new EventEmitter<boolean>();


  totalCost: number = 0;
  paymentMethod: string = '';
  taxRate: number = 0.05;
  constructor(
    private formStateService: FormStateService,
    private api: ServiceProviderService,
    private router: Router
  ) { }
  ngOnInit(): void {
    if (!this.form) {
      this.form = new FormGroup({});
    }
    this.form.statusChanges.subscribe(() => {
      this.formValidity.emit(this.form.valid);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedPlan'] && this.selectedPlan) {
      this.calculateTotal();
    }
  }

  calculateTotal(): void {
    if (this.selectedPlan) {
      this.totalCost = this.selectedPlan.price + (this.selectedPlan.price * this.taxRate);
    }
  }

  selectPaymentMethod(method: string): void {
    this.paymentMethod = method;
  }
  clearForm(): void {
    this.formStateService.clearFormData();
  }

  onSubmit(): void {

  }
}