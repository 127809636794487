<p-toast position="bottom-center"></p-toast>
<div class="form-container">
  <div style="margin-bottom: 43px" class="font-semibold text-xl mt-5">
    Insurance details
  </div>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

    <button
      type="submit"
      class="custom-upload-button"
      [disabled]="form.invalid"
      (click)="onSubmit()"
    >
      {{ isEditMode ? "Update" : "Finish" }}
      <span class="arrow">➔</span>
    </button>
  </form>
  <div *ngIf="isLoading" class="overlay">
    <div class="spinner">
      <div class="spinner-bar"></div>
    </div>
  </div>
</div>
