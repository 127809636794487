<div class="form-container">
  <div class="header-container">
    <div class="font-semibold text-xl mt-5" style="margin-bottom: 43px;">
      {{ countryId == 2 ? 'Upload Mulkiya' : 'Upload RTO' }}
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" class="custom-upload-button">
      Upload and Next
      <span class="arrow">➔</span>
    </button>
  </form>
</div>