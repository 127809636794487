<div class="overlay">
  <div class="modal" style="width: 100%; height: 100%; overflow-y: auto">
    <div class="modal-content">
      <div
        class="flex justify-content-between flex-wrap"
        style="
          padding-top: 10px;
          padding-right: 2px;
          padding-left: 82px;
          border-bottom: 1px solid #f2f4f7;
        "
      >
        <div class="img">
          <div class="flex justify-content-start flex-wrap">
            <div class="flex align-items-center justify-content-center mr-1">
              <img
                src="assets/image/Logomark.png"
                alt="Logo"
                class="svg-black"
              />
            </div>
            <div class="flex align-items-center justify-content-center">
              <p style="color: #1d2939; font-weight: 700; font-size: 18px">
                Auto E Care
              </p>
            </div>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center">
          <p style="font-weight: 700; color: #333; font-size: 24px">
            {{ editMode ? 'Update Service Seeker Form' : 'Add Service Seeker Form' }}
          </p>
        </div>
        <div class="align-items-center justify-content-center m-3">
          <i
            class="pi pi-times"
            style="cursor: pointer; font-size: 1em"
            (click)="close()"
          ></i>
        </div>
      </div>
      <div class="flex" style="height: calc(100vh - 70px); overflow-y: auto">
        <div class="sidebar" style="margin-top: 82px">
          <ul>
            <li>
              <a
                (click)="setActiveSection('personal-details')"
                [ngClass]="{ active: activeSection === 'personal-details' }"
              >
                <i class="pi pi-user"></i>Customer Details
              </a>
            </li>

            <!-- Always show Upload RTO -->
            <li>
              <a
                *ngIf="countryId == 2; else uploadRTO"
                (click)="setActiveSection('upload-section')"
                [ngClass]="{ active: activeSection === 'upload-section' }"
              >
                <i class="pi pi-cloud-upload"></i>Upload Mulkiya
              </a>
              <ng-template #uploadRTO>
                <a
                  (click)="setActiveSection('upload-section')"
                  [ngClass]="{ active: activeSection === 'upload-section' }"
                >
                  <i class="pi pi-cloud-upload"></i>Upload RTO
                </a>
              </ng-template>
            </li>

            <li>
              <a
                (click)="setActiveSection('vehicle-details')"
                [ngClass]="{ active: activeSection === 'vehicle-details' }"
              >
                <i class="pi pi-list"></i> Vehicle Details
              </a>
            </li>

            <li>
              <a
                (click)="setActiveSection('tyre-battery-details')"
                [ngClass]="{ active: activeSection === 'tyre-battery-details' }"
              >
                <i class="pi pi-list"></i> Tyre, Battery & Engine Oil Details
              </a>
            </li>

            <!-- Always show Insurance Details -->
            <li>
              <a
                (click)="setActiveSection('insurance-details')"
                [ngClass]="{ active: activeSection === 'insurance-details' }"
              >
                <i class="pi pi-shield"></i>Insurance Details
              </a>
            </li>

            <!-- Hide Pricing section in edit mode -->
            <li *ngIf="!editMode">
              <a
                (click)="setActiveSection('pricing')"
                [ngClass]="{ active: activeSection === 'pricing' }"
              >
                <i class="pi pi-box"></i>Pricing
              </a>
            </li>

            <!-- Hide Payment section in edit mode -->
            <li *ngIf="!editMode">
              <a
                (click)="setActiveSection('payments')"
                [ngClass]="{ active: activeSection === 'payments' }"
              >
                <i class="pi pi-credit-card"></i>Payment
              </a>
            </li>
          </ul>
        </div>

        <div class="content" style="flex-grow: 1">
          <app-user-personal-detail
            *ngIf="activeSection === 'personal-details'"
            (nextSection)="setActiveSection('upload-section')"
          ></app-user-personal-detail>

          <app-upload-documents
            *ngIf="activeSection === 'upload-section'"
            (nextSection)="setActiveSection('vehicle-details')"
          ></app-upload-documents>

          <app-add-vehicle-details
            *ngIf="activeSection === 'vehicle-details'"
            (nextSection)="setActiveSection('tyre-battery-details')"
          ></app-add-vehicle-details>

          <app-add-tyre-battery-details
            *ngIf="activeSection === 'tyre-battery-details'"
            (nextSection)="setActiveSection('insurance-details')"
          ></app-add-tyre-battery-details>

          <app-add-insurance-details
            (close)="close()"
            (updateUserList)="onUpdateUserList()"
            *ngIf="activeSection === 'insurance-details'"
          ></app-add-insurance-details>

          <app-user-pricing-plan
            (planSelected)="onPlanSelected($event)"
            *ngIf="activeSection === 'pricing'"
            (nextSection)="setActiveSection('payments')"
          ></app-user-pricing-plan>

          <app-user-payment
            [selectedPlan]="selectedPlan"
            *ngIf="activeSection === 'payments'"
          ></app-user-payment>
        </div>
      </div>
    </div>
  </div>
</div>
