<div class="mb-8">
    <p style="font-weight: 500;font-size: 18px;margin-bottom: 40px;">Basic Vehicle Details</p>
    <div class="flex flex-row flex-wrap ">
      <div>
        <div style="width: 225px;">
          <p class="business-type-label">VIN</p>
          <p class="business-type-value">{{ user?.vin }}</p>
        </div>
      </div>
      <div>
        <div style="width: 255px;padding-left: 52px;">
          <p class="business-type-label">Vehicle Registration Number</p>
          <p class="business-type-value">{{ user?.vehicleRegistrationNumber }}</p>
        </div>
      </div>
    </div>

    <div class="flex flex-row flex-wrap" style="margin-top: 40px;">
      <div style="width: 225px;">
        <p class="business-type-label">Vehicle maker</p>
        <p class="business-type-value">{{ user?.vehicleMaker?.name }}</p>
      </div>
      <div style="width: 255px;padding-left: 52px;">
        <p class="business-type-label">Vehicle model</p>
        <p class="business-type-value">
          {{ user?.vehicleModel?.name}}
        </p>
      </div>
      <div style="width: 225px;padding-left: 52px;">
        <p class="business-type-label">Model Year</p>
        <p class="business-type-value">{{ user?.modelYear }}</p>
      </div>
      <div style="width: 255px;padding-left: 52px;">
        <p class="business-type-label">Origin</p>
        <p class="business-type-value">{{ user?.vehicleOrigin?.name }}</p>
      </div>
    </div>

    <div class="flex flex-row flex-wrap " style="margin-top: 40px;">
      <div>
        <div style="width: 225px;">
          <p class="business-type-label">Color</p>
          <p class="business-type-value">{{ user?.color }}</p>
        </div>
      </div>
      <div>
        <div style="width: 255px;padding-left: 52px;">
          <p class="business-type-label">Painting type</p>
          <p class="business-type-value">{{ user?.paintingType?.name }}</p>
        </div>
      </div>
    </div>
  </div>