import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-owner-details',
  templateUrl: './vehicle-owner-details.component.html',
  styleUrl: '../user-details.component.scss'
})
export class VehicleOwnerDetailsComponent {
  @Input() user: any;
}
